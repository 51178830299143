<template>
    <div>
        <h2 class="mb-1">Active Treatment Report</h2>
        
        <div style="display: flex; flex-wrap: wrap; align-items: center;" class="mb-2">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon mr-50"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="prevRange()"
            >
                <feather-icon icon="ChevronLeftIcon" />
            </b-button>

            <b-form-group
                label="Start Date"
                class="mr-50 w-10"
            >
                <date-picker v-model="startDate" type="month" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-if="selectedRangeDate === 'month' || selectedRangeDate === 'quarter'"></date-picker>
                <date-picker v-model="startDate" type="year" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="selectedRangeDate === 'year'"></date-picker>
                <date-picker v-model="startDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" v-else-if="!selectedRangeDate || selectedRangeDate === 'custom'"></date-picker>
            </b-form-group>

            <b-form-group
                label="End Date"
                class="mr-50"
            >
                <date-picker v-model="endDate" type="date" format="MM-DD-YYYY" value-type="DD/MM/YYYY" :disabled="selectedRangeDate === 'month' || selectedRangeDate === 'quarter' || selectedRangeDate === 'year' ? true : false"></date-picker>
            </b-form-group>

            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon ml-0"
                style="height: 37px; margin-top: 12px;"
                @click.stop.prevent="nextRange()"
            >
                <feather-icon icon="ChevronRightIcon" />
            </b-button>
                
            <div :class="!selectedRangeDate ? 'pl-1' : 'pl-1'">
                <b-form-group>
                    <b-form-radio-group
                        v-model="selectedRangeDate"
                        button-variant="outline-primary range-date"
                        buttons
                        class="p-0 mr-2"
                    >
                        <b-form-radio style="height: 37px; padding: 11px;" v-for="(option,index) in optionsRangeDate" :key="index" :value="option.value">{{option.text}}</b-form-radio>
                        <b-form-radio style="height: 37px; padding: 10px;" v-if="selectedRangeDate" value="x">x</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
            </div>

            <b-form-group
                label="Region"
            >
                <v-select
                    v-model="region"
                    placeholder="Region"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsRegion"
                    style="background: #fff; min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <div v-if="region === 'NYC'">
                <b-form-group
                    label="Borough"
                >
                    <v-select
                        v-model="borough"
                        placeholder="Any Borough"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="optionsBorough"
                        style="background: #fff; min-width: 200px;"
                        class="mr-2"
                    />
                </b-form-group>
            </div>

            <b-form-group
                label="Program"
            >
                <b-form-select
                    v-model="clinicianProgram"
                    inline
                    :options="optionsProgram"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    style="min-width: 200px;"
                    class="mr-2"
                />
            </b-form-group>

            <div>
                <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-primary"
                    class="mb-1 mt-2 p-75"
                    @click="activeFilterFunction"
                >
                    <feather-icon
                        icon="Xicon"
                        class="mr-50"
                    />
                    <span class="align-middle">Reset filters</span>
                </b-button>
            </div>
        <!-- </b-row> -->
        </div>

        <b-row>
            <!-- Total Clients In Treatment -->
            <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countTotalClientsInTreatment && countTotalClientsInTreatment !== 'NaN' ? countTotalClientsInTreatment.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Clients In Treatment</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Total Gamblers In Treatment -->
            <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countTotalFamilyMembersInTreatment && countTotalFamilyMembersInTreatment !== 'NaN' ? countTotalFamilyMembersInTreatment.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Family Members In Treatment</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <!-- Total Gamblers In Treatment -->
            <b-col
                lg="4"
                md="4"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countTotalGamblersInTreatment && countTotalGamblersInTreatment !== 'NaN' ? countTotalGamblersInTreatment.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Gamblers In Treatment</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <!-- Other/Blank -->
            <b-col
                lg="6"
                md="6"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="isBusy">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ countOtherBlank && countOtherBlank !== 'NaN' ? countOtherBlank.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Other/Blank In Treatment</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>

            <b-col
                lg="6"
                md="6"
                sm="12"
            >
                <b-card no-body>
                    <b-card-body class="d-flex justify-content-between align-items-center">
                    <div class="truncate">
                        <div class="text-primary" v-if="loadTotalClients">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                        <h2 class="mb-25 font-weight-bolder" v-else>
                        {{ totalNonEnglishClients && totalNonEnglishClients !== 'NaN' ? totalNonEnglishClients.toLocaleString('en-US') : 0 }}
                        </h2>
                        <span>Total Non-English Clients</span>
                    </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <download-csv 
            :data="json_data"
            class="mb-1 btn btn-default"
            name="reports-active-treatment.csv"
            :style="readyToExport ? 'background: #94221e; color: #fff; cursor: pointer; opacity: 1;' : 'background: #94221e; color: #fff; cursor: no-drop; opacity: 0.5;'"
            delimiter=";"
            v-if="isDataSpecialist"
        >
            CSV Export
            <b-spinner small v-if="!readyToExport" />
        </download-csv>
    </div>
</template>

<script>
import { 
    BForm, BButton, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BTable, BFormInvalidFeedback, BFormSelect, BCard, BAvatar, BCardBody,
    BSpinner, BFormRadioGroup, BFormRadio,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { db, auth } from '@/firebase'

export default {
    data() {
        return {
            // validation
            required,
            email,
            totalRows: 0,
            isDataSpecialist: false,
            readyToExport: false,

            // filters
            startDate: null,
            endDate: null,
            selectedRangeDate: 'month',
            optionsRangeDate: [
                { text: 'Month', value: 'month' },
                { text: 'Quarter', value: 'quarter' },
                { text: 'Year', value: 'year' },
                { text: 'Custom', value: 'custom' },
            ],
            region: '',
            borough: '',
            language: '',
            progress: '',
            insurance: '',

            // data report
            countTotalClientsInTreatment: 0,
            countTotalFamilyMembersInTreatment: 0,
            countTotalGamblersInTreatment: 0,
            averageTreatmentSessionsCompleted: 0,
            averageLengthOfStay: 0,
            totalNonEnglishClients: 0,
            countOtherBlank: 0,

            // options
            optionsRegion: [],
            optionsBorough: [
                'Bronx',
                'Brooklyn',
                'Manhattan',
                'Queens',
                'Staten Island'
            ],
            // optionsRegion: [
            //     'Bronx',
            //     'Brooklyn',
            //     'Central',
            //     'Finger Lakes',
            //     'Long Island',
            //     'Manhattan',
            //     'Mid-Hudson',
            //     'Northeast',
            //     'Queens',
            //     'Staten Island',
            //     'Western'
            // ],
            optionsLanguage: [],
            optionsClientProgress: [
                'Any Client Status',
                'Engaged',
                'Closed - Attended First Session',
                'Closed - Did not attend first session',
                'Closed - Received information',
                'Closed - Referred to OASAS Program',
                'External Referral',
                'On Hold'
            ],
            optionsInsurance: [],

            // settings table and edit view
            statusEdit: false,
            itemsActivity: [],
            fieldsTable: [
                { key: 'clientID', sortable: false },
                { key: 'clientName', sortable: false },
                { key: 'status', sortable: false },
                { key: 'contactDate', sortable: false },
                { key: 'admissionDate', sortable: false },
                { key: 'dischargeDate', sortable: false },
                // { key: 'actions', sortable: false },
            ],
            clinicianProgram: null,
            optionsProgram: [],

            // get data from db
            optionsClient: [],
            optionsClinician: [],

            // json to export csv
            json_data: [],

            // filter
            clientActivityName: null,
            clientActivityStatus: null,

            // reports cards
            totalGroupSessions: 0,
            totalIndividualSessions: 0,
            totalRemoteSessions: 0,
            // totalNonEnglishClients: 0,
            clientSatisfaction: 0,
            improved: 0,

            // overlay data
            rangeDinamic: false,
            isBusy: true,
            loadTotalClients: true,
        }
    },
    components: {
        BForm,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BTable,
        BFormInvalidFeedback,
        BFormSelect,
        BCard,
        BAvatar,
        BCardBody,
        BSpinner,
        BFormRadioGroup,
        BFormRadio,
        DatePicker,
        
        // select
        vSelect,

        // date
        flatPickr,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    computed: {
        ...mapGetters(['arrayFiltradoReportsActiveTreatment','arrayFiltradoReportsActiveTreatmentClaims']),
        ...mapState(['searchReportsActiveTreatmentStartDateState','searchReportsActiveTreatmentEndDateState','searchReportsActiveTreatmentRegionState','searchReportsActiveTreatmentProgressState','searchReportsActiveTreatmentLanguageState','searchReportsActiveTreatmentInsuranceState','searchReportsActiveTreatmentProgramState'])
    },
    watch: {
        selectedRangeDate(val) {
            this.functionDateRange(val)
        },
        clientActivityStatus(val) {
            this.searchClientActivityStatus(val)
        },
        startDate(val) {
            // console.log(val)
            this.searchReportsActiveTreatmentStartDate(val)
            this.functionDateRange(this.selectedRangeDate)
            this.rangeDinamic ? this.rangeDinamic = false : ''
            // this.selectedRangeDate = ''
        },
        endDate(val) {
            // console.log(val)
            this.searchReportsActiveTreatmentEndDate(val)
        },
        region(val) {
            this.searchReportsActiveTreatmentRegion(val)
        },
        borough(val) {
            this.searchReportsActiveTreatmentRegion(val)
        },
        progress(val) {
            this.searchReportsActiveTreatmentProgress(val)
        },
        language(val) {
            this.searchReportsActiveTreatmentLanguage(val)
        },
        insurance(val) {
            this.searchReportsActiveTreatmentInsurance(val)
        },
        clinicianProgram(val) {
            this.searchReportsActiveTreatmentProgram(val)
        },
        arrayFiltradoReportsActiveTreatment(val) {
            // this.isBusy = true
            this.countTotalClientsInTreatment = 0
            this.countTotalFamilyMembersInTreatment = 0
            this.countTotalGamblersInTreatment = 0
            this.averageLengthOfStay = 0
            this.totalNonEnglishClients = 0
            this.countOtherBlank = 0
            var test = 0
            var test1 = 0
            var test2 = 0
            var test3 = 0
            var other = 0
            var arr = []
            var arrayExport = []
            this.readyToExport = false

            val.arregloFiltrado.forEach(dataClientActivity => {
                // console.log(dataClientActivity.dateOfAdmissionFormat)
                // if(val.count === 0) {
                //     if(dataClientActivity.dateOfAdmission) {
                //         //  && dataClientActivity.status !== 'On Hold'
                //         this.countTotalClientsInTreatment += 1
                //     }
                // }

                // if(!arr.includes(dataClientActivity.clientID)) {
                //     arr.push(dataClientActivity.clientID)

                    if(dataClientActivity.dateOfAdmission && dataClientActivity.newOrReturningClient && dataClientActivity.presentingProblemsAtAdmission && dataClientActivity.diagnosticCriteriaMetAtAdmission) {
                        test3 += 1
                    }
                    if(dataClientActivity.statusLogical === 'Admitted') {
                        test += 1
                    } 
                    else if(dataClientActivity.statusLogical === 'Discharged') {
                        test1 += 1
                    }
                    else {
                        test2 += 1
                    }
                
                    // console.log(val.count)
                    // if(val.count === 0) {
                    //     // if(dataClientActivity.dateOfAdmission && dataClientActivity.newOrReturningClient && dataClientActivity.presentingProblemsAtAdmission && dataClientActivity.diagnosticCriteriaMetAtAdmission && dataClientActivity.clientInvolvedWithLegalSystem && dataClientActivity.clientReleaseSigned) {
                    //     if(dataClientActivity.dateOfAdmission) {
                    //         // console.log('first',dataClientActivity.dischargeStatus)
                    //         // Total Clients In Treatment
                    //         // if(dataClientActivity.clinicianStatus === 'active') {
                    //             this.countTotalClientsInTreatment += 1
                    //         // }
                    //     }
                    // }

                    // if(dataClientActivity.enterAdmissionDischargeInformation) {
                    // if(dataClientActivity.dateOfAdmission && dataClientActivity.newOrReturningClient && dataClientActivity.presentingProblemsAtAdmission && dataClientActivity.diagnosticCriteriaMetAtAdmission && dataClientActivity.clientInvolvedWithLegalSystem && dataClientActivity.clientReleaseSigned) {
                    if(dataClientActivity.dateOfAdmission) {
                        this.countTotalClientsInTreatment += 1
                        // if(dataClientActivity.dischargeStatus === 'Planned') {
                            // Total Clients In Treatment
                            // this.countTotalClientsInTreatment += 1

                            if(dataClientActivity.contactStatus) {
                                // Total Family Members In Treatment
                                if(dataClientActivity.contactStatus === 'Family Member') {
                                    this.countTotalFamilyMembersInTreatment += 1
                                }

                                // Total Gamblers In Treatment
                                if(dataClientActivity.contactStatus === 'Problem Gambler') {
                                    this.countTotalGamblersInTreatment += 1
                                }

                                // Total Gamblers Other
                                if(dataClientActivity.contactStatus !== 'Family Member' && dataClientActivity.contactStatus !== 'Problem Gambler') {
                                    other += 1
                                    this.countOtherBlank += 1
                                }
                            } else {
                                other += 1
                                this.countOtherBlank += 1
                            }

                            if(dataClientActivity.dateOfDischarge && dataClientActivity.dateOfDischarge !== 'N/A') {
                                // diff dataClientActivity.data().dateOfDischarge - dataClientActivity.data().dateOfAdmission
                                this.averageLengthOfStay = 6
                            }

                            // total non-english
                            if(dataClientActivity.language !== 'English') {
                                this.totalNonEnglishClients += 1
                                this.loadTotalClients = false
                            }
                        // }
                    }
                // }

                arrayExport.push({
                    // clientID: dataClientActivity.,
                    // clientName: dataClientActivity.,
                    status: dataClientActivity.statusLogical,
                    // statusLogical: dataClientActivity.,
                    contactDate: dataClientActivity.contactDate,
                    // contactDateFormat: dataClientActivity.,
                    // formatAdmission: dataClientActivity.dateOfAdmission ? moment(dataClientActivity.dateOfAdmission,'MM/DD/YYYY').format('DD/MM/YYYY') : '',
                    dateOfAdmission: dataClientActivity.dateOfAdmission,
                    // dateOfAdmissionFormat: dataClientActivity.,
                    // formatDischarge: dataClientActivity.dateOfDischarge ? moment(dataClientActivity.dateOfDischarge,'MM/DD/YYYY').format('DD/MM/YYYY') : '',
                    dateOfDischarge: dataClientActivity.dateOfDischarge,
                    // dateOfDischargeFormat: dataClientActivity.,
                    contactStatus: dataClientActivity.contactStatus,
                    enterAdmissionDischargeInformation: dataClientActivity.enterAdmissionDischargeInformation,
                    newOrReturningClient: dataClientActivity.newOrReturningClient,
                    presentingProblemsAtAdmission: dataClientActivity.presentingProblemsAtAdmission,
                    diagnosticCriteriaMetAtAdmission: dataClientActivity.diagnosticCriteriaMetAtAdmission,
                    clientInvolvedWithLegalSystem: dataClientActivity.clientInvolvedWithLegalSystem,
                    clientReleaseSigned: dataClientActivity.clientReleaseSigned,
                    initialAppointmentDate: dataClientActivity.initialAppointmentDate ? moment(dataClientActivity.initialAppointmentDate,'DD/MM/YYYY').format('MM/DD/YYYY') : '',
                    // created: dataClientActivity.,
                    // clinicianStatus: dataClientActivity.,
                    dischargeStatus: dataClientActivity.dischargeStatus,
                    region: dataClientActivity.region,
                    // insurance: dataClientActivity.,
                    clientStatusAdmission: dataClientActivity.clientStatusAdmission ? dataClientActivity.clientStatusAdmission : '',
                    primaryICD10: dataClientActivity.primaryICD10 ? dataClientActivity.primaryICD10 : '',
                    locadtrgCompleted: dataClientActivity.locadtrg ? dataClientActivity.locadtrg : '',
                    LOCADTRAssessmentID: dataClientActivity.LOCADTRAssessmentID ? dataClientActivity.LOCADTRAssessmentID : '',

                    // new fields
                    otherProblemsAdmission: dataClientActivity.otherProblemsAdmission ? dataClientActivity.otherProblemsAdmission : '',
                    race: dataClientActivity.race ? dataClientActivity.race : '',
                    householdIncome: dataClientActivity.householdIncome ? dataClientActivity.householdIncome : '',
                    gamblingTypes: dataClientActivity.gamblingTypes ? dataClientActivity.gamblingTypes : '',
                    employmentStatus: dataClientActivity.employmentStatus ? dataClientActivity.employmentStatus : '',
                    educationLevels: dataClientActivity.educationLevels ? dataClientActivity.educationLevels : '',
                    ethnicity: dataClientActivity.ethnicity ? dataClientActivity.ethnicity : '',
                    veteran: dataClientActivity.veteran ? dataClientActivity.veteran : '',
                    zipCode: dataClientActivity.zipCode ? dataClientActivity.zipCode : '',
                    industryOfEmployment: dataClientActivity.industryOfEmployment ? dataClientActivity.industryOfEmployment : '',
                    maritalStatus: dataClientActivity.maritalStatus ? dataClientActivity.maritalStatus : '',
                    religiousPreference: dataClientActivity.religiousPreference ? dataClientActivity.religiousPreference : '',

                    // client information
                    clientID: dataClientActivity.idClient ? dataClientActivity.idClient : '',
                    insurance: dataClientActivity.insurance ? dataClientActivity.insurance : '',
                    county: dataClientActivity.county ? dataClientActivity.county : '',
                    language: dataClientActivity.language ? dataClientActivity.language : '',
                    // gender: dataClientActivity.gender ? dataClientActivity.gender : '',
                    program: dataClientActivity.clinicianProgram ? dataClientActivity.clinicianProgram : ''
                })
                // }
            })
            // console.log(other)

            // this.json_data = [
            //     {
            //         'Filter Start Date': this.startDate,
            //         'Filter End Date': this.endDate,
            //         'Filter Region': this.region,
            //         'Filter Borough': this.borough,
            //         'Total Clients In Treatment': this.countTotalClientsInTreatment.toString().replace(',', ''),
            //         'Total Family Members In Treatment': this.countTotalFamilyMembersInTreatment.toString().replace(',', ''),
            //         'Total Gamblers In Treatment': this.countTotalGamblersInTreatment.toString().replace(',', ''),
            //         'Total Non-English Clients': this.totalNonEnglishClients.toString().replace(',', '')
            //     }
            // ]

            // this.json_data = val.arregloFiltrado
            this.json_data = arrayExport
            this.readyToExport = true

            // if(test >= 100 && this.startDate && this.endDate) {
            //     this.countTotalClientsInTreatment = test
            // }
            // console.log(test, test1, test2, test3, val.count, val.county)

            // if(val.count > 0) {
            //     this.countTotalClientsInTreatment = val.count
            // }
            // this.isBusy = false
        },
        arrayFiltradoReportsActiveTreatmentClaims(val) {
            this.totalGroupSessions = 0
            this.totalIndividualSessions = 0
            this.totalRemoteSessions = 0

            this.improved = 0
            this.averageTreatmentSessionsCompleted = 0
            val.forEach(itemClaim => {
                if(itemClaim.approvalStatus === 'approved' || itemClaim.approvalStatus === 'paid') {
                    this.totalGroupSessions += parseInt(itemClaim.noGroupSessions, 10)
                    this.totalIndividualSessions += parseInt(itemClaim.noIndividualSessions, 10)
                    this.totalRemoteSessions += parseInt(itemClaim.noRemoteSessions, 10)
                }
                this.improved += 1
            })
            if(this.totalIndividualSessions === 0 && this.improved === 0) this.averageTreatmentSessionsCompleted = 0
            else this.averageTreatmentSessionsCompleted = (this.totalIndividualSessions / this.improved).toFixed(2)
        }
    },
    methods: {
        ...mapActions(['searchClientActivityName','searchClientActivityStatus','searchClientActivityClinicianName','searchReportsActiveTreatmentRegion','searchReportsActiveTreatmentProgress','searchReportsActiveTreatmentLanguage','searchReportsActiveTreatmentInsurance','searchClientActivityClinicianStatus','searchClientActivityClientID','searchReportsActiveTreatmentStartDate','searchReportsActiveTreatmentEndDate','setSnapshotClientActivityReportsActiveTreatment','setSnapshotClientClaimReportsActiveTreatment','searchReportsActiveTreatmentProgram']),
        activeFilterFunction() {
            this.startDate = null
            this.endDate = null
            this.selectedRangeDate = 'month'
            this.region = ''
            this.borough = ''
            this.language = ''
            this.progress = ''
            this.insurance = ''
            this.clinicianProgram = null
            // this.functionFilters()
            this.activeFilters = false
        },
        functionDateRange(val) {
            if(val === 'month') {
                // console.log(this.startDate)
                if(this.startDate) {
                    // '24/03/2022'
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(1, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(this.startDate,this.endDate)
                }
            } else if(val === 'quarter') {
                if(this.startDate) {
                    var formatDate1 = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var formatDate = moment(this.startDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var formatDate1 = moment(Date.now()).format('MM/DD/YYYY')
                    var formatDate = moment(Date.now()).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(3, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                }
            } else if(val === 'year') {
                if(this.startDate) {
                    var formatDateNew = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                    var getYear = moment(formatDateNew).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = '01/01/'+getYear
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,expiredDate,dateUpdateFormat)
                } else {
                    var getYear = moment(Date.now()).format('YYYY')
                    var formatDate1 = moment('01/01/'+getYear).format('MM/DD/YYYY')
                    var formatDate = moment('01/01/'+getYear).format('DD/MM/YYYY')
                    var expiredDate = moment(formatDate1).add(12, 'months').subtract(1, 'day')
                    var dateUpdateFormat = moment(expiredDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                    this.startDate = formatDate
                    this.endDate = dateUpdateFormat
                    // console.log(formatDate,dateUpdateFormat)
                }
            } else if(val === 'x') {
                this.selectedRangeDate = ''
                this.startDate = null
                this.endDate = null
            }
        },
        prevRange() {
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).subtract(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
                this.functionDateRange(this.selectedRangeDate)
            }
        },
        nextRange() {
            // console.log(this.startDate, this.selectedRangeDate)
            if(this.startDate && this.selectedRangeDate) {
                var formatDate = moment(this.startDate,'DD/MM/YYYY').format('MM/DD/YYYY')
                var subtractDate = moment(formatDate).add(1, 'months')
                var dateUpdateFormat = moment(subtractDate,'MM/DD/YYYY').format('DD/MM/YYYY')
                console.log(dateUpdateFormat)

                this.rangeDinamic = true
                this.startDate = dateUpdateFormat
                this.functionDateRange(this.selectedRangeDate)
            }
        },
        functionFilters() {
            if(this.clientActivityStatus) {
                this.searchClientActivityStatus(this.clientActivityStatus)
            } else {
                this.searchClientActivityStatus('')
            }

            if(this.startDate) {
                this.searchReportsActiveTreatmentStartDate(this.startDate)
            } else {
                this.searchReportsActiveTreatmentStartDate('')
            }

            if(this.endDate) {
                this.searchReportsActiveTreatmentEndDate(this.endDate)
            } else {
                this.searchReportsActiveTreatmentEndDate('')
            }

            if(this.region) {
                this.searchReportsActiveTreatmentRegion(this.region)
            } else {
                this.searchReportsActiveTreatmentRegion('')
            }

            if(this.progress) {
                this.searchReportsActiveTreatmentProgress(this.progress)
            } else {
                this.searchReportsActiveTreatmentProgress('')
            }

            if(this.language) {
                this.searchReportsActiveTreatmentLanguage(this.language)
            } else {
                this.searchReportsActiveTreatmentLanguage('')
            }

            if(this.insurance) {
                this.searchReportsActiveTreatmentInsurance(this.insurance)
            } else {
                this.searchReportsActiveTreatmentInsurance('')
            }
        }
    },
    created() {
        this.countOtherBlank = 0

        var user = auth.currentUser
        db.collection('usuarios').doc(user.uid).get()
        .then(doc => {
            this.isDataSpecialist = false
            if(doc.data().role === 'staff') {
                if(doc.data().permissions.includes('data specialist')) {
                    this.isDataSpecialist = true
                }
            } else if(doc.data().role === 'admin') {
                this.isDataSpecialist = true
            }
        })
        
        this.startDate = this.searchReportsActiveTreatmentStartDateState ? this.searchReportsActiveTreatmentStartDateState : null
        this.endDate = this.searchReportsActiveTreatmentEndDateState ? this.searchReportsActiveTreatmentEndDateState : null
        this.region = this.searchReportsActiveTreatmentRegionState ? this.searchReportsActiveTreatmentRegionState : ''
        this.language = this.searchReportsActiveTreatmentProgressState ? this.searchReportsActiveTreatmentProgressState : ''
        this.progress = this.searchReportsActiveTreatmentLanguageState ? this.searchReportsActiveTreatmentLanguageState : ''
        this.insurance = this.searchReportsActiveTreatmentInsuranceState ? this.searchReportsActiveTreatmentInsuranceState : ''
        this.clinicianProgram = this.searchReportsActiveTreatmentProgramState ? this.searchReportsActiveTreatmentProgramState : null
        this.$http.get('https://us-central1-nycpg-67e48.cloudfunctions.net/app/api/prod/active-treatment/reports')
        .then(res => {
            this.itemsActivity = []
            this.countTotalClientsInTreatment = res.data.counter.countTotalClientsInTreatment
            this.countTotalFamilyMembersInTreatment = res.data.counter.countTotalFamilyMembersInTreatment
            this.countTotalGamblersInTreatment = res.data.counter.countTotalGamblersInTreatment
            this.averageLengthOfStay = res.data.counter.averageLengthOfStay
            this.totalNonEnglishClients = res.data.counter.totalNonEnglishClients

            this.setSnapshotClientActivityReportsActiveTreatment(res.data.activity)
            this.isBusy = false
            this.loadTotalClients = false
        })

        // db.collection('clientsActivity').where('clientStatus', '==', 'active').get()
        // .then(docsClients => {
        //     this.itemsActivity = []
        //     this.countTotalClientsInTreatment = 0
        //     this.countTotalFamilyMembersInTreatment = 0
        //     this.countTotalGamblersInTreatment = 0
        //     this.averageLengthOfStay = 0
        //     this.totalNonEnglishClients = 0
        //     // var test = 0
        //     docsClients.forEach(dataClientActivity => {
        //         if(dataClientActivity.id !== 'counter') {
        //             // get data client
        //             var clientID = dataClientActivity.data().client
        //             var clientName = ''
        //             var language = ''
        //             var insurance = ''
        //             var statusClient = ''

        //             if(dataClientActivity.data().dateOfAdmission && dataClientActivity.data().newOrReturningClient && dataClientActivity.data().presentingProblemsAtAdmission && dataClientActivity.data().diagnosticCriteriaMetAtAdmission && dataClientActivity.data().clientInvolvedWithLegalSystem && dataClientActivity.data().clientReleaseSigned) {
        //                 // Total Clients In Treatment
        //                 this.countTotalClientsInTreatment += 1
        //             }
                    
        //             if(dataClientActivity.data().dateOfAdmission && dataClientActivity.data().newOrReturningClient && dataClientActivity.data().presentingProblemsAtAdmission && dataClientActivity.data().diagnosticCriteriaMetAtAdmission && dataClientActivity.data().clientInvolvedWithLegalSystem && dataClientActivity.data().clientReleaseSigned) {
        //                 // Total Clients In Treatment
        //                 // this.countTotalClientsInTreatment += 1

        //                 if(dataClientActivity.data().contactStatus) {
        //                     // Total Family Members In Treatment
        //                     if(dataClientActivity.data().contactStatus === 'Family Member') {
        //                         this.countTotalFamilyMembersInTreatment += 1
        //                     }

        //                     // Total Gamblers In Treatment
        //                     if(dataClientActivity.data().contactStatus === 'Problem Gambler') {
        //                         this.countTotalGamblersInTreatment += 1
        //                     }
        //                 }

        //                 if(dataClientActivity.data().dateOfDischarge) {
        //                     // diff dataClientActivity.data().dateOfDischarge - dataClientActivity.data().dateOfAdmission
        //                     this.averageLengthOfStay = 6
        //                 }

        //                 // total non-english
        //                 if(dataClientActivity.data().clientLang && dataClientActivity.data().clientLang !== 'English') {
        //                     this.totalNonEnglishClients += 1
        //                     this.loadTotalClients = false
        //                 }
        //             }

        //             var dateFormat = dataClientActivity.data().dateOfContact ? new Date(dataClientActivity.data().dateOfContact.split('-')) : ''
        //             var dateFormatAdmission = dataClientActivity.data().dateOfAdmission ? new Date(dataClientActivity.data().dateOfAdmission.split('-')) : ''
        //             var claimRegion = dataClientActivity.data().regionalCFEReceivingTheCall ? dataClientActivity.data().regionalCFEReceivingTheCall : ''

        //             var obj = {
        //                 clientID: clientID,
        //                 idClient: dataClientActivity.data().clientID ? dataClientActivity.data().clientID : '',
        //                 insurance: dataClientActivity.data().clientInsurance ? dataClientActivity.data().clientInsurance : '',
        //                 county: dataClientActivity.data().clientCounty ? dataClientActivity.data().clientCounty : '',
        //                 language: dataClientActivity.data().clientLang ? dataClientActivity.data().clientLang : '',
        //                 gender: dataClientActivity.data().clientGender ? dataClientActivity.data().clientGender : '',
        //                 clientName: clientName,
        //                 status: dataClientActivity.data().activityStatus,
        //                 statusLogical: dataClientActivity.data().activityStatusLogical ? dataClientActivity.data().activityStatusLogical : '',
        //                 contactDate: dataClientActivity.data().dateOfContact,
        //                 contactDateFormat: dateFormat ? moment(dateFormat,'DD/MM/YYYY').format('DD/MM/YYYY') : '',
        //                 dateOfAdmission: dataClientActivity.data().dateOfAdmission ? dataClientActivity.data().dateOfAdmission : '',
        //                 dateOfAdmissionFormat: dateFormatAdmission ? moment(dateFormatAdmission,'DD/MM/YYYY').format('DD/MM/YYYY') : '',
        //                 dateOfDischarge: dataClientActivity.data().dateOfDischarge ? dataClientActivity.data().dateOfDischarge : '',
        //                 dateOfDischargeFormat: dataClientActivity.data().dateOfDischarge ? moment(dataClientActivity.data().dateOfDischarge,'MM-DD-YYYY').format('DD/MM/YYYY') : '',
        //                 contactStatus: dataClientActivity.data().contactStatus,
        //                 enterAdmissionDischargeInformation: dataClientActivity.data().enterAdmissionDischargeInformation,
        //                 newOrReturningClient: dataClientActivity.data().newOrReturningClient,
        //                 presentingProblemsAtAdmission: dataClientActivity.data().presentingProblemsAtAdmission,
        //                 diagnosticCriteriaMetAtAdmission: dataClientActivity.data().diagnosticCriteriaMetAtAdmission,
        //                 clientInvolvedWithLegalSystem: dataClientActivity.data().clientInvolvedWithLegalSystem,
        //                 clientReleaseSigned: dataClientActivity.data().clientReleaseSigned,
        //                 initialAppointmentDate: dataClientActivity.data().initialAppointmentDate ? moment(dataClientActivity.data().initialAppointmentDate,'MM-DD-YYYY').format('DD/MM/YYYY') : '',
        //                 created: dataClientActivity.data().created ? moment(dataClientActivity.data().created,'MM-DD-YYYY').format('DD/MM/YYYY') : '',
        //                 clinicianStatus: dataClientActivity.data().clinicianStatus ? dataClientActivity.data().clinicianStatus : '',
        //                 dischargeStatus: dataClientActivity.data().dischargeStatus ? dataClientActivity.data().dischargeStatus : '',

        //                 region: dataClientActivity.data().regionalCFEReceivingTheCall ? dataClientActivity.data().regionalCFEReceivingTheCall : '',
        //                 borough: dataClientActivity.data().borough && claimRegion === 'NYC' ? dataClientActivity.data().borough : '',
        //                 clinicianProgram: dataClientActivity.data().clinicianProgram ? dataClientActivity.data().clinicianProgram : '',
        //                 arrayClinicianProgram: dataClientActivity.data().arrayClinicianProgram ? dataClientActivity.data().arrayClinicianProgram : ''
        //             }
        //             this.itemsActivity.push(obj)
        //         }
        //     })
        //     this.setSnapshotClientActivityReportsActiveTreatment(this.itemsActivity)
        //     this.isBusy = false
        // })

        db.collection('taxonomies').doc('client').get()
        .then(docTaxonomia => {
            // profile fields
            this.optionsLanguage = docTaxonomia.data().primaryLanguage
            this.optionsInsurance = docTaxonomia.data().insuranceType
        })

        db.collection('taxonomies').doc('clinician').get()
        .then(docTaxonomia => {
            // profile fields
            this.optionsRegion = docTaxonomia.data().region

            var arrayProgram = [{value: null, text: 'Any Program'}]
            docTaxonomia.data().program.forEach(item => {
                if(item !== 'OASAS') {
                    arrayProgram.push({value: item, text: item})
                }
            })
            this.optionsProgram = arrayProgram
        })
    }
}
</script>

<style>
.mx-input {
    height: 37px !important;
}
.form-control {
    background: #fff !important;
    background-color: #fff !important;
}
.range-date {
    padding: 8px;
    margin-top: 27px;
}
.vs__dropdown-option {
  white-space: normal !important;
}
.vs__dropdown-toggle {
  max-height: 38px;
}
.vs__selected-options {
  overflow: auto;
}
.vs__selected-options {
    overflow-x: hidden;
    overflow-y: hidden;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>